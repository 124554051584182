import React from 'react';

// Amplify
import Amplify, {I18n} from "aws-amplify";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import {AmplifyAuthenticator} from '@aws-amplify/ui-react';
import AmplifyI18n from "amplify-i18n"
import awsconfig from './aws-exports';

// Components
import RegisterUser from "./RegisterUser";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

// Setup
Amplify.configure(awsconfig);

// @ts-ignore
AmplifyI18n.configure()
I18n.setLanguage("pt-BR")


const App: React.FunctionComponent = () => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
      <>
        <Header />
        <RegisterUser />
        <Footer />
      </>
  ) : (
      <>
          <div style={{display:'flex'}}>
            <img src="logo.png" alt="Neuro-Sono" style={{margin: '20px auto',textAlign: 'center'}}/>
          </div>
          <div className="App" slot="sign-in" style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              backgroundImage: `url('bg-login.jpg')`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover'
          }}>
              <AmplifyAuthenticator />
          </div>
      </>
  );
}

export default App;
