import React, {useState} from "react";

// Cognito
import {Auth} from "aws-amplify";

import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from "axios";

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

// Custom Mask Input
import PhoneNumberCustom from "./Components/PhoneNumberCustom";
import DateFormatCustom from "./Components/DateFormatCustom";

// Validations
import {validatePhone} from 'validations-br';
import AlertDialog from "./Components/DialogForm";

const schema = yup.object().shape({
    fullname:       yup.string().required('O campo Nome Completo é obrigatório.'),
    email:          yup.string().email('O valor do campo E-mail deve ser válido.').required('O campo E-mail é obrigatório.'),
    phone_number:   yup.string().test(
        "phone_number",
        "O campo Nº Telefone não é válido, preecha novamente.",
        (value) => validatePhone(value!)
    ),
    cpf:                yup.string().required("O campo CPF é obrigatório."),
    mother_name:        yup.string(),
    dt_birth:    yup.string()
});

type Inputs = {
    fullname: string,
    email: string,
    phone_number: string,
    cpf: string,
    mother_name: string,
    dt_birth: string
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#3db6ef",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#272d7d"
    }
}));

const RegisterUser: React.FunctionComponent = () => {

    const [loading, setLoading] = useState(false);
    const [modalStatus, setModalStatus] = React.useState(false);
    const [headTitle, setHeadTitle] = React.useState('');

    const { register, handleSubmit, formState: { errors }, reset } = useForm<Inputs>({
        resolver: yupResolver(schema)
    });

    const classes = useStyles();

    const onSubmit: SubmitHandler<Inputs> = async data => {
        setLoading(true);

        Axios.post(
            'https://n63ijtie1d.execute-api.sa-east-1.amazonaws.com/prod/create-new-patient',
            data,
            {
                headers: {'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
            }
        )
        .then(function (response) {
            handleClickOpen()
            setHeadTitle(response.data.data)
            reset();
        })
        .catch(function (error) {
            handleClickOpen()
            setHeadTitle(error.response.data.data)
        })
        .finally(() => setLoading(false));

    };

    const handleClickOpen = () => { setModalStatus(true); };
    const handleClose = () => {
        setModalStatus(false);
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <PersonAddIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Cadastrar Paciente
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="fullname"
                                label="Nome Completo"
                                autoFocus
                                {...register('fullname')}
                                error={ !!errors.fullname }
                                helperText={ errors.fullname?.message ?? '' }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="cpf"
                                label="CPF"
                                autoFocus
                                {...register('cpf')}
                                error={ !!errors.cpf }
                                helperText={ errors.cpf?.message ?? '' }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type={"email"}
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                autoFocus
                                {...register('email')}
                                error={ !!errors.email }
                                helperText={ errors.email?.message ?? '' }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="phone_number"
                                label="Nº Telefone"
                                autoFocus
                                {...register('phone_number')}
                                error={ !!errors.phone_number }
                                helperText={ errors.phone_number?.message ?? '' }
                                InputProps={{
                                    inputComponent: PhoneNumberCustom as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="dt_birth"
                                label="Data de Nascimento"
                                autoFocus
                                {...register('dt_birth')}
                                error={ !!errors.dt_birth }
                                helperText={ errors.dt_birth?.message ?? '' }
                                InputProps={{
                                    inputComponent: DateFormatCustom as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="mother_name"
                                label="Nome da Mãe/Responsável"
                                autoFocus
                                {...register('mother_name')}
                                error={ !!errors.mother_name }
                                helperText={ errors.mother_name?.message ?? '' }
                            />
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        {loading ? 'Processando ...' : 'Cadastrar'}
                    </Button>
                </form>
                </div>
            </Container>
            <AlertDialog handleClose={handleClose} modalStatus={modalStatus} title={headTitle}/>
        </>
    )
}

export default RegisterUser;
