import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: '#020818',
            display: 'block',
            padding: '2.3rem',
            marginTop: '5rem'
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export default function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.root}>
            <div style={{display: 'block', margin: '0 auto', 'marginBottom': 20, textAlign: 'center'}}>
                <img src="logo-gray.png" alt="" height="40px"/>
            </div>
            <Typography variant="body1" color="textSecondary" align="center"  style={{color:'#f1f1f1'}}>

                {'Todos os Direitos Reservados © '} <br/>
                <Link color="inherit" href="https://neuro-sono.com">
                    <b>Clínica de Neurologia e Distúrbios do Sono | Neuro-Sono</b>
                </Link>{' '}
            </Typography>
        </footer>
    );
}