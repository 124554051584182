import React from 'react';
import MaskedInput from 'react-text-mask';

interface DateFormatCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

export default function DateFormatCustom(props: DateFormatCustomProps) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}