import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {AmplifySignOut} from '@aws-amplify/ui-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export default function Header() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" style={{backgroundColor:'#272d7d'}}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Portal do Registrador
                    </Typography>
                    <AmplifySignOut buttonText="Sair" />
                </Toolbar>
            </AppBar>
        </div>
    );
}